import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

import { waitForControllersToInitialize } from "./../../helpers/stimulus_util";

const Honeybadger = require("@honeybadger-io/js");

export default class CourseController extends Controller {
  static targets = ["sectionLink", "video", "videoContainer"];

  static values = {
    completedPath: String,
    currentSectionIndex: Number,
    initialSectionId: String,
    isPreview: Boolean,
    isSignedIn: Boolean,
    previewEndedHtml: String,
    sections: Array,
    statusComplete: String,
    statusInProgress: String,
    statusPath: String,
  };

  initialize() {
    this.setInitialSectionIndex();
    waitForControllersToInitialize().then(() => {
      this.prepareNextSection({ autoplay: false, pushState: false });
    });

    window.addEventListener("popstate", () => location.reload());
  }

  prepareNextSection({ autoplay = true, pushState = true } = {}) {
    const currentIndex = this.currentSectionIndexValue;
    const nextIndex = currentIndex + 1;
    const nextSection = this.sectionsValue[nextIndex];

    if (nextSection) {
      let path = nextSection.path;
      path = autoplay ? path + "?autoplay=1" : path;

      if (pushState) {
        history.pushState(null, nextSection.title, path);
      }

      this.currentSectionIndexValue = nextIndex;
      this.prepareSection(nextSection);
    } else if (this.completedPathValue) {
      window.location = this.completedPathValue;
    }
  }

  prepareSection({ id, wistiaId }) {
    this.sectionLinkTargets.forEach((link) => {
      if (link.dataset.sectionId === id) {
        link.classList.add("js-current-section");
        link.scrollIntoView({ behavior: "smooth", block: "nearest" });
      } else {
        link.classList.remove("js-current-section");
      }
    });

    this.getWistiaController().embedVideo(wistiaId);

    if (this.isSignedInValue) {
      this.bindUserEvents();
    } else {
      this.bindGuestEvents();
    }
  }

  bindUserEvents() {
    if (!this.isPreviewValue) {
      this.videoTarget.addEventListener(
        "play",
        () => {
          const { wistiaId } = this.getCurrentSection();
          this.createStatus(wistiaId, this.statusInProgressValue);
        },
        { once: true },
      );

      this.videoTarget.addEventListener(
        "watchedtocompletion",
        () => {
          const { wistiaId } = this.getCurrentSection();
          this.createStatus(wistiaId, this.statusCompleteValue);
        },
        { once: true },
      );

      this.videoTarget.addEventListener(
        "nextsection",
        () => {
          this.prepareNextSection();
        },
        { once: true },
      );
    }
  }

  bindGuestEvents() {
    this.videoTarget.addEventListener(
      "end",
      () => {
        this.videoContainerTarget.outerHTML = this.previewEndedHtmlValue;
      },
      { once: true },
    );
  }

  getWistiaController() {
    return this.application.getControllerForElementAndIdentifier(
      this.videoTarget,
      "wistia",
    );
  }

  getCurrentSection() {
    const index = this.currentSectionIndexValue;
    return this.sectionsValue[index];
  }

  setInitialSectionIndex() {
    const initialIndex = this.sectionsValue.findIndex((section) => {
      return section.id === this.initialSectionIdValue;
    });

    if (initialIndex === -1) {
      this.currentSectionIndexValue = -1;
    } else {
      this.currentSectionIndexValue = initialIndex - 1;
    }
  }

  async createStatus(wistiaId, state) {
    try {
      const body = {
        state,
        wistia_id: wistiaId,
      };
      const response = await post(this.statusPathValue, {
        contentType: "application/json",
        body: body,
      });

      if (!response.ok) {
        throw new Error(
          `Unable to create Status with ${JSON.stringify(body)}: ${response.statusCode}`,
        );
      }
    } catch (error) {
      Honeybadger.notify(error);
    }
  }
}
